@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Josefin Sans', sans-serif;
  /* font-family: brandon-grotesque, sans-serif;
  src: local('brandon-grotesque'), url(./assets/fonts/brandon-grotesque-light-58a8a4b38001d.otf) format('opentype'); */
   /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5{
  font-family: 'Roboto Condensed', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-theme-blue{
  background-color: #3fbfef;
}
/* .text-theme-blue{
  color: #362eff;
} */

.opacity-90{
  opacity: 0.9 !important;
}

#courses{
  max-width: 100%;
  /* overflow-x: hidden; */
}

#course-details-list > li{
  padding-top: 10px;
}
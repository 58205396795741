.carousel {
  display: inline-flex;
  overflow-x: hidden;
  /* scroll snap is a great feature which will center the image on snap on touch screen devices  */
  scroll-snap-type: x mandatory;
  /* all below will hide the scrollbar on all browsers. */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.learn-btn{
  /* margin-top: 40px; */
  /* position: absolute !important; */
  position: relative;
  margin-left: 120px;
  /* border-radius: 5px */
}

.c-image{
  max-height: 250px;
  max-width: 100%;
  filter: brightness(0.4);
  border-radius: 15px
}
figure{
  height: 360px;
}

.caption{
  width: 70%; 
  position: relative;
  text-align: left;
  margin-bottom: 40px;
  text-indent: 120px; 
  /* background: #0000007a;  */
  color: white;
  margin-top: -220px;
  /* margin-left: 80px; */
  font-size: 24px;
}

@media (max-width: 768px) {
  .c-image{
    height: 300px;
  }
  .caption{
    font-size: 15px;
    width: 99%; 
  }
  figure{
    height: 300px;
  }
}
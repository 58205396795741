table{
    width: 100%;
    margin-top: 20px;
    text-align: center;
}
td{
    /* width: 33% !important; */
    border: 1px solid #fff;
}

/* Code for animation -- to change direction of chevron just change -40px to 40px and vice versa */
.glyphicon-chevron-down-custom span, .glyphicon-chevron-up-custom span {width: 5px; height: 2px; background-color: rgb(255, 255, 255); display: inline-block; transition: all .1s linear;}
.glyphicon-chevron-down-custom .sp-1, .glyphicon-chevron-up-custom .sp-2 {transform: skewY(-40deg);}
.glyphicon-chevron-up-custom .sp-1, .glyphicon-chevron-down-custom .sp-2 {transform: skewY(40deg);}
.glyphicon {top: -1px;}

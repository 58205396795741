.breadcrumb-item{
    display: inline-block;
    margin-right: 5px;
    font-style: italic;
    color: aqua;
}

.breadcrumb-item:not(:last-child):after{
    content: " / ";
    color: white;
}